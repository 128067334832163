import React from 'react';
import { Culture } from '../api';

export enum MeasurementSystem {
  metric = 'METRIC',
  imperial = 'IMPERIAL',
}

export type MeasurementSystemContextType = [
  MeasurementSystem,
  (measurementSystem: MeasurementSystem) => void,
];

export const getMeasurementSystemByLanguage = (language: string): MeasurementSystem => {
  if (language === 'en-US') return MeasurementSystem.imperial;
  return MeasurementSystem.metric;
};

export const getMeasurementSystemByCountry = (country: string): MeasurementSystem => {
  if (['United States', 'Bahamas'].includes(country)) return MeasurementSystem.imperial;
  return MeasurementSystem.metric;
};

export const getPreferredMeasurementSystem = (
  culture: Culture | null = null,
): MeasurementSystem => {
  let measurementSystem: MeasurementSystem | undefined;
  if (culture?.AutoDetectedCountry) {
    measurementSystem = getMeasurementSystemByCountry(culture.AutoDetectedCountry);
  } else {
    const { language } = navigator;
    measurementSystem = getMeasurementSystemByLanguage(language);
  }
  return measurementSystem;
};

export const MeasurementSystemContext = React.createContext<MeasurementSystemContextType>([
  getPreferredMeasurementSystem(),
  () => undefined,
]);
MeasurementSystemContext.displayName = 'MeasurementSystemContext';
