import { ThemeOptions } from '@mui/material/styles/createTheme';
import ProximaNovaBold from '../fonts/proxima/proximanova-bold.otf';
import ProximaNovaRegular from '../fonts/proxima/proximanova-regular.otf';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    body: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    body: PaletteOptions['primary'];
  }

  interface Theme {
    suuntoColors: {
      CloudyGrey: React.CSSProperties['color'];
      DarkestGrey: React.CSSProperties['color'];
      DarkGrey: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    suuntoColors: {
      CloudyGrey: React.CSSProperties['color'];
      DarkestGrey: React.CSSProperties['color'];
      DarkGrey: React.CSSProperties['color'];
    };
  }
}
declare module '@mui/material/Slider' {
  interface SliderPropsColorOverrides {
    body: true;
  }
}

declare module '@mui/material/Button' {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    body: true;
  }
  interface ButtonPropsColorOverrides {
    neutral: true;
    body: true;
  }
}
declare module '@mui/material/Radio' {
  interface RadioPropsSizeOverrides {
    large: true;
  }
}

export const enum Text {
  dark = '#303030',
  light = '#f1f1f1',
}

const enum SuuntoColors {
  CloudyGrey = '#D8DBDD',
  DarkGrey = '#7E8084',
  DarkestGrey = '#3D3D3D',
}

const theme: ThemeOptions = {
  spacing: (factor: number) => `${factor}rem`,
  suuntoColors: {
    CloudyGrey: SuuntoColors.CloudyGrey,
    DarkestGrey: SuuntoColors.DarkestGrey,
    DarkGrey: SuuntoColors.DarkGrey,
  },
  typography: {
    fontFamily: ['Proxima Nova', 'sans-serif'].join(','),
    fontSize: 10,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '2.4rem',
    },
    subtitle2: {
      fontSize: '1.2rem',
    },
    h4: {
      /* Suunto Paragraphs / Body Mega Bold */
      fontSize: '2.4rem',
      fontWeight: 'bold',
      lineHeight: 9 / 8,
    },
    h5: {
      /* Suunto / Numbers3 */
      fontSize: '1.8rem',
      lineHeight: 7 / 8,
    },
    h6: {
      /* Suunto / Body Large */
      fontSize: '1.6rem',
    },
    body1: {
      /* Suunto / Body Regular */
      fontSize: '1.4rem',
    },
    body2: {
      /* Suunto / Body Small */
      fontSize: '1.2rem',
    },
    button: {
      fontSize: '1.6rem',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    action: {
      disabledBackground: SuuntoColors.CloudyGrey,
    },
    background: {
      paper: '#fff',
      default: '#fff',
    },
    body: {
      main: Text.dark,
    },
    primary: {
      main: '#16B4EA',
      dark: '#1190BB',
      light: '#8ED2EA',
      contrastText: '#fff',
    },
    neutral: {
      main: '#ACAFB6' /* Suunto/MediumGrey */,
    },
    text: {
      primary: Text.dark,
    },
    divider: '#ECEDF0',
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '0.6em 1em',
          textTransform: 'none',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.82)',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': {
          fontFamily: 'Proxima Nova',
          fontWeight: 'normal',
          src: `url("${ProximaNovaRegular}") format("opentype")`,
        },
        fallbacks: {
          '@font-face': [
            {
              fontFamily: 'Proxima Nova',
              fontWeight: 'bold',
              src: `url("${ProximaNovaBold}") format("opentype")`,
            },
          ],
        },
        html: {
          fontSize: '62.5%',
          maxWidth: '100vw',
          height: '100%',
        },
        body: {
          maxWidth: '100%',
          margin: 0,
          height: 'fill-available',
          fallbacks: [{ height: '100vh' }],
          position: 'relative',
        },
        'svg, path': {
          fill: 'currentColor',
        },
        '#root': {
          height: 'fill-available',
          fallbacks: [{ height: '100vh' }],
        },
        '*': {
          boxSizing: 'border-box',
        },
      },
    },
  },
};

const darkTheme: ThemeOptions = {
  ...theme,
  palette: {
    mode: 'dark',
    action: {
      disabledBackground: SuuntoColors.DarkestGrey,
    },
    background: {
      paper: Text.dark,
      default: Text.dark,
    },
    text: {
      primary: Text.light,
    },
    body: {
      main: Text.light,
    },
    neutral: {
      main: '#ACAFB6' /* Suunto/MediumGrey */,
    },
    primary: {
      main: '#1190BB',
      light: '#16B4EA',
      contrastText: '#fff',
    },
  },
};

export const dark = darkTheme;

export const light = theme;
