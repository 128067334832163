import { LocationPoint } from '../types/WorkoutPayload';
import { LLAT, InterpolatedChain } from './CameraPath';

export interface Bounds {
  latMin: number;
  latMax: number;
  lonMin: number;
  lonMax: number;
  altMin: number;
  altMax: number;
  latCenter: number;
  lonCenter: number;
  altCenter: number;
}

export class Track {
  bounds: Bounds;
  private readonly tStart: number;
  private readonly tEnd: number;
  private readonly duration: number;

  constructor(public points: LocationPoint[]) {
    this.bounds = Track.getBounds(points);

    this.tStart = points[0].timestamp;
    this.tEnd = points[points.length - 1].timestamp;
    this.duration = this.tEnd - this.tStart;
  }

  getLookAtPoints(numberOfPoints: number, centeringBehaviour: InterpolatedChain): LLAT[] {
    const step = this.duration / (numberOfPoints - 1);
    const lookAtPoints: LLAT[] = [];

    let t = this.tStart;

    this.points.forEach((point) => {
      if (point.timestamp >= t) {
        const progress = (point.timestamp - this.tStart) / this.duration;
        const factor = centeringBehaviour.getValue(progress);

        lookAtPoints.push({
          lat: point.latitude * (1 - factor) + this.bounds.latCenter * factor,
          lon: point.longitude * (1 - factor) + this.bounds.lonCenter * factor,
          alt: point.altitude * (1 - factor) + this.bounds.altCenter * factor,
          t: (t - this.tStart) / this.duration,
        });

        t += step;
      }
    });

    return lookAtPoints;
  }

  static getBounds(points: LocationPoint[]): Bounds {
    let latMin = points[0].latitude;
    let latMax = points[0].latitude;
    let lonMin = points[0].longitude;
    let lonMax = points[0].longitude;
    let altMin = points[0].altitude;
    let altMax = points[0].altitude;

    points.forEach((point) => {
      latMin = Math.min(latMin, point.latitude);
      latMax = Math.max(latMax, point.latitude);
      lonMin = Math.min(lonMin, point.longitude);
      lonMax = Math.max(lonMax, point.longitude);
      altMin = Math.min(altMin, point.altitude);
      altMax = Math.max(altMax, point.altitude);
    });

    const latCenter = (latMin + latMax) / 2;
    const lonCenter = (lonMin + lonMax) / 2;
    const altCenter = (altMin + altMax) / 2;

    return {
      latMin,
      latMax,
      latCenter,
      lonMin,
      lonMax,
      lonCenter,
      altMin,
      altMax,
      altCenter,
    };
  }
}
