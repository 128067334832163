import React from 'react';
import amplitude from 'amplitude-js';

export const enum AmplitudeEvent {
  SharedWorkoutScreen = 'SharedWorkoutScreen',
  SharedWorkoutPopularProductClicked = 'SharedWorkoutPopularProductClicked',
  SharedWorkoutPlaybackCompleted = 'SharedWorkoutPlaybackCompleted',
  SharedWorkoutPlaybackStarted = 'SharedWorkoutPlaybackStarted',
  SharedWorkoutAttachedMediaViewed = 'SharedWorkoutAttachedMediaViewed',
}

export interface AmplitudeData {
  ActivityType?: number;
  MediaType?: 'Photo' | 'Video';
  Duration?: number;
  Distance?: number;
  Source?: 'LeftPane' | 'TrackThumbnail';
  AverageHR?: number;
  TotalAscent?: number;
  WatchModel?: string;
  WatchColour?: string;
  PlaceInProductCarousel?: number;
}

export const AmplitudeContext = React.createContext<AmplitudeData>({});
AmplitudeContext.displayName = 'AmplitudeContext';

export const useAmplitude = (): {
  logEvent: (event: AmplitudeEvent, additionalData?: AmplitudeData) => void;
} => {
  const amplitudeData = React.useContext(AmplitudeContext);

  const logEvent = React.useCallback(
    (eventName: AmplitudeEvent, additionalData?: AmplitudeData) => {
      amplitude.getInstance().logEvent(eventName, { ...amplitudeData, ...additionalData });
    },
    [amplitudeData],
  );
  return { logEvent };
};
