export type WorkoutPosition = {
  x: number;
  y: number;
};

export interface Point {
  timestamp: number;
}
export interface ValuePoint extends Point {
  value: number;
}

export interface Extension {
  type: WorkoutExtensionType;
}

export interface ValuePointExtension extends Extension {
  points: ValuePoint[];
  timestamps?: number[];
  values?: number[];
}

export enum WorkoutExtensionType {
  AltitudeStreamExtension = 'AltitudeStreamExtension',
  AppBundleIDExtension = 'AppBundleIDExtension',
  CadenceStreamExtension = 'CadenceStreamExtension',
  DepthStreamExtension = 'DepthStreamExtension',
  DistanceDeltaStreamExtension = 'DistanceDeltaStreamExtension',
  DistanceLapExtension = 'DistanceLapExtension',
  DiveHeaderExtension = 'DiveHeaderExtension',
  DurationLapExtension = 'DurationLapExtension',
  EnergyConsumptionStreamExtension = 'EnergyConsumptionStreamExtension',
  EpocStreamExtension = 'EpocStreamExtension',
  FitnessExtension = 'FitnessExtension',
  HeartRateExtension = 'HeartRateExtension',
  HeartrateStreamExtension = 'HeartrateStreamExtension',
  IBIStreamExtension = 'IBIStreamExtension',
  IntervalStreamExtension = 'IntervalStreamExtension',
  IntensityExtension = 'IntensityExtension',
  LocationStreamExtension = 'LocationStreamExtension',
  ManualLapStreamExtension = 'ManualLapStreamExtension',
  MultisportMarkerExtension = 'MultisportMarker',
  PauseMarkerExtension = 'PauseMarkerExtension',
  PoolLengthExtension = 'PoolLengthExtension',
  PowerStreamExtension = 'PowerStreamExtension',
  RelativePerformanceLevelStreamExtension = 'RelativePerformanceLevelStreamExtension',
  SeaLevelPressureStreamExtension = 'SeaLevelPressureStreamExtension',
  SkiExtension = 'SkiExtension',
  SkiHeaderExtension = 'SkiHeaderExtension',
  SkiTurnExtension = 'SkiTurnExtension',
  SMLExtension = 'SMLExtension',
  SpeedStreamExtension = 'SpeedStreamExtension',
  StepCountDeltaStreamExtension = 'StepCountDeltaStreamExtension',
  StrokeRateStreamExtension = 'StrokeRateStreamExtension',
  StrokesDeltaStreamExtension = 'StrokesDeltaStreamExtension',
  SummaryExtension = 'SummaryExtension',
  SwimmingHeaderExtension = 'SwimmingHeaderExtension',
  SwolfStreamExtension = 'SwolfStreamExtension',
  TemperatureStreamExtension = 'TemperatureStreamExtension',
  VerticalLapExtension = 'VerticalLapExtension',
  VerticalSpeedStreamExtension = 'VerticalSpeedStreamExtension',
}

export type AltitudeStreamPoint = ValuePoint;
export interface AltitudeStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.AltitudeStreamExtension;
  points: AltitudeStreamPoint[];
}

export type HeartRateStreamPoint = ValuePoint;
export interface HeartrateStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.HeartrateStreamExtension;
  points: HeartRateStreamPoint[];
}

export type CadenceStreamPoint = ValuePoint;
export interface CadenceStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.CadenceStreamExtension;
  points: CadenceStreamPoint[];
}

export type DistanceDeltaPoint = ValuePoint;
export interface DistanceDeltaStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.DistanceDeltaStreamExtension;
  points: DistanceDeltaPoint[];
}

export interface FitnessExtension extends Extension {
  type: WorkoutExtensionType.FitnessExtension;
  maxHeartRate: number | null;
  vo2Max: number | null;
  estimatedVo2Max: number | null;
}

export interface PauseMarker {
  automatic: boolean;
  endTime: number;
  startTime: number;
}

export interface PauseMarkerExtension extends ValuePointExtension {
  type: WorkoutExtensionType.PauseMarkerExtension;
  pauseMarkers: PauseMarker[];
}

export interface LocationPoint {
  altitude: number;
  bearing: null;
  ehpe: null;
  latitude: number;
  longitude: number;
  speed: number;
  timestamp: number;
}
export interface LocationStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.LocationStreamExtension;
  locationPoints: LocationPoint[];
}

export interface ManualLapStreamExtension extends Extension {
  type: WorkoutExtensionType.ManualLapStreamExtension;
  markers: Record<string, unknown>[];
}

export type SeaLevelPoint = ValuePoint;
export interface SeaLevelPressureStreamExtension extends Extension {
  type: WorkoutExtensionType.SeaLevelPressureStreamExtension;
  points: SeaLevelPoint[];
}

export type SpeedStreamPoint = ValuePoint;
export interface SpeedStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.SpeedStreamExtension;
  points: SpeedStreamPoint[];
}

export type TemperaturePoint = ValuePoint;
export interface TemperatureStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.TemperatureStreamExtension;
  points: TemperaturePoint[];
}

export type VerticalSpeedPoint = ValuePoint;
export interface VerticalSpeedStreamExtension extends ValuePointExtension {
  type: WorkoutExtensionType.VerticalSpeedStreamExtension;
  points: VerticalSpeedPoint[];
}

export interface Gear {
  manufacturer: string;
  name: string;
  serialNumber: string;
  softwareVersion: string;
  hardwareVersion: string;
}

export interface SummaryExtension extends Extension {
  [key: string]: unknown;
  type: WorkoutExtensionType.SummaryExtension;
  avgSpeed: number | null;
  avgPower: number | null;
  maxPower: number | null;
  avgCadence: number | null;
  maxCadence: number | null;
  ascent: number | null;
  descent: number | null;
  ascentTime: number | null;
  descentTime: number | null;
  pte: number | null;
  peakEpoc: number | null;
  performanceLevel: number | null;
  weather: unknown | null;
  minTemperature: number | null;
  avgTemperature: number | null;
  workoutType: unknown;
  feeling: number;
  gear: Gear;
  exerciseId: string | null;
  recoveryTime: number | null;
  apps: Record<string, unknown>[];
}

export interface MultisportMarker {
  ActivityID: number;
  EndTime: number;
  StartTime: number;
  Totals: MultisportMarkerTotals | null;
}

export interface MultisportMarkerTotals {
  activityId: string;
  altitude: {
    avg: number | null;
    min: number | null;
    max: number | null;
  } | null;
  cadence: {
    avg: number | null;
    min: number | null;
    max: number | null;
  } | null;
  ascent: number | null;
  ascentTime: number | null;
  descent: number | null;
  descentMax: number | null;
  descentTime: number | null;
  distance: number | null;
  distanceMax: number | null;
  downhillGrade: unknown | null;
  duration: number | null;
  energy: number | null;
  hr: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  power: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  recoveryTime: number | null;
  speed: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  strokeRate: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  strokes: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  swimmingStyle: string | null;
  swolf: {
    min: number | null;
    max: number | null;
    avg: number | null;
  } | null;
  temperature: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
  verticalSpeed: {
    min: number | null;
    avg: number | null;
    max: number | null;
  } | null;
}

export interface Zone {
  totalTime: number;
  lowerLimit: number;
}

export interface Zones {
  zone1: Zone;
  zone2: Zone;
  zone3: Zone;
  zone4: Zone;
  zone5: Zone;
}

export interface IntensityExtension extends Extension {
  type: WorkoutExtensionType.IntensityExtension;
  overallIntensity: unknown | null;
  physiologicalThresholds: unknown | null;
  zones: {
    heartRate: Zones | null;
    power: Zones | null;
    speed: Zones | null;
  };
}

export interface MultisportMarkerExtension extends Extension {
  type: WorkoutExtensionType.MultisportMarkerExtension;
  Markers: Array<MultisportMarker>;
}

export interface TotalTimeOnRouteRanking {
  originalRanking: number;
  originalNumberOfWorkouts: number;
}
export interface WorkoutRankings {
  totalTimeOnRouteRanking: TotalTimeOnRouteRanking;
}

export interface DiveHeaderExtension extends Extension {
  type: WorkoutExtensionType.DiveHeaderExtension;
  maxDepth: number;
  pauseDuration: number;
  maxDepthTemperature: number;
  avgDepth: number;
  diveMode: string;
  diveNumberInSeries: number;
  surfaceTime: number | null;
  gasesUsed: string[];
  gasQuantitites: null;
  gasConsumption: null;
  algorithmLock: false;
  cns: number;
  otu: number;
  personalSetting: null;
  altitudeSetting: number;
  algorithm: string;
  minGF: number;
  maxGF: number;
}

export interface SwimmingHeaderExtension extends Extension {
  type: WorkoutExtensionType.SwimmingHeaderExtension;
  avgSwolf: number;
  avgStrokeRate: number;
  poolLength: number;
}

export interface SkiExtension extends Extension {
  type: WorkoutExtensionType.SkiExtension;
  runs: [];
  statistics: {
    ascentMeters: number;
    avgSpeed: number;
    descentDistanceMeters: number;
    descentDurationSeconds: number;
    descentMeters: number;
    maxSpeed: number;
    numberOfRuns: number;
  };
}

export interface HrData {
  avg: number;
  hrmax: number;
  max: number;
  userMaxHr: number;
  workoutAvgHR: number;
  workoutMaxHR: number;
}

export type Extensions = Extension[];

export enum PhotoSize {
  small = 's',
  medium = 'm',
  large = 'l',
}

export interface PhotoSizeItem {
  size: PhotoSize;
  url: string;
  height: number;
  width: number;
}

export interface Photo {
  coverImage: boolean;
  description: string;
  height: number;
  key: string;
  location: null;
  timestamp: number;
  totalTime: number;
  url: string;
  username: string;
  width: number;
  workoutKey: string;
  sizes: PhotoSizeItem[];
}

export interface WorkoutPayload {
  username: string;
  sharingFlags: number;
  activityId: number;
  key: string;
  description: string;
  startTime: number;
  stopTime: number;
  totalTime: number;
  totalDistance: number;
  totalAscent: number;
  totalDescent: number;
  startPosition: WorkoutPosition;
  stopPosition: WorkoutPosition;
  centerPosition: WorkoutPosition;
  maxSpeed: number;
  polyline: string;
  visibilityGroups: boolean;
  visibilityExplore: boolean;
  visibilityFriends: boolean;
  visibilityFacebook: boolean;
  visibilityTwitter: boolean;
  stepCount: number;
  recoveryTime: number;
  cumulativeRecoveryTime: number;
  rankings: WorkoutRankings;
  reactionCount: number;
  isManuallyAdded: boolean;
  hrdata: HrData;
  energyConsumption: number;
  commentCount: number;
  viewCount: number;
  extensions: Extensions;
  minAltitude: number;
  maxAltitude: number;
  avgPace: number;
  avgSpeed: number;
  userPhoto: string | null;
  fullname: string;
  photos: Photo[];
  availableExtensions: WorkoutExtensionType[];
}
