import React, { ReactElement } from 'react';
import { AmplitudeEvent, useAmplitude } from '../Amplitude/Amplitude';
import noOp from '../helpers/noOp';
interface PositionServiceProps {
  children: ReactElement;
}

export const PositionContext = React.createContext<{
  position: number;
  setPosition: React.Dispatch<React.SetStateAction<number>>;
  isPlaying: boolean;
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isMapInitialized: boolean;
  setIsMapInitialized: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  position: 0,
  setPosition: noOp,
  isPlaying: false,
  setIsPlaying: noOp,
  isMapInitialized: false,
  setIsMapInitialized: noOp,
});
PositionContext.displayName = 'PositionContext';

export const PositionService = (props: PositionServiceProps): ReactElement => {
  const { children } = props;
  const [position, setPosition] = React.useState<number>(0);
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const [startTime, setStartTime] = React.useState<number>(0);
  const [isMapInitialized, setIsMapInitialized] = React.useState<boolean>(false);
  const duration = 40000;
  const { logEvent } = useAmplitude();

  React.useEffect(() => {
    if (isMapInitialized && isPlaying) {
      logEvent(AmplitudeEvent.SharedWorkoutPlaybackStarted);
      setStartTime(Date.now() / duration - position);
    } else {
      setStartTime(0);
    }
  }, [isMapInitialized, isPlaying, logEvent]);

  React.useEffect(() => {
    setIsPlaying(isMapInitialized);
  }, [isMapInitialized]);

  React.useEffect(() => {
    if (!isMapInitialized || !startTime) return;
    let animationFrameRequestId = requestAnimationFrame(() => {
      if (!isPlaying) return;

      const newPosition = Math.min(Date.now() / duration - startTime, 1);

      if (newPosition === 1) {
        logEvent(AmplitudeEvent.SharedWorkoutPlaybackCompleted);
        setIsPlaying(false);
      }

      setPosition(newPosition);
      animationFrameRequestId = 0;
    });

    return () => {
      if (animationFrameRequestId) {
        cancelAnimationFrame(animationFrameRequestId);
      }
    };
  }, [isPlaying, position, startTime, isMapInitialized, logEvent]);

  return (
    <PositionContext.Provider
      value={{
        position,
        isPlaying,
        setPosition,
        setIsPlaying,
        isMapInitialized,
        setIsMapInitialized,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
};
