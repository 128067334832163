const CryptoJS = require('crypto-js');
const UAParser = require('ua-parser-js');

var AnalysisEvent = function (options) {
    if (options) {
        this.globalConfig = { ...this.globalConfig, ...options };
        this.eventConfig.client = options.client || '';
        this.loaded = true;
    } else {
        this.loaded = false;
    }
};

AnalysisEvent.prototype.init = function (options) {
    if (!this.loaded) {
        this.globalConfig = { ...this.globalConfig, ...options };
        this.eventConfig.client = options.client || '';
        this.loaded = true;
    }
}

AnalysisEvent.prototype.utils = {
    safeCall: function (fn, args) {
        if (fn && typeof fn === "function") {
            fn(args);
        }
    },

    requestSuccess: function (r) {
        return r && r.status === 200;
    },
    // 获取Query Language参数
    getLanguageFromQuery() {
        var queryString = window.location.search;
        var langRegex = /\b(lang|locale)=([a-zA-Z]+)(?:[_-]([a-zA-Z]+))?/gi;
        var obj = null;
        queryString.replace(langRegex, (s0, s1, language, country) => {
            obj = {};
            obj.language = language.toLowerCase();
            obj.country = !country ? null : country.toUpperCase();
            return null;
        });
        return obj;
    },
    //获取Navigator中的语言
    getLanguageFromNavigator() {
        var lang = navigator.userLanguage || navigator.language;
        if (lang) {
            var langRegex = /([a-zA-Z]+)(?:[_-]([a-zA-Z]+))?$/gi;
            var obj = null;
            lang.replace(langRegex, (s0, language, country) => {
                obj = {};
                obj.language = language.toLowerCase();
                obj.country = !country ? null : country.toUpperCase();
                return null;
            });
            return obj;
        }
        return null;
    },
    getLanguageAndRegion: function () {
        let language = this.getLanguageFromQuery() || this.getLanguageFromNavigator();
        if (!language) {
            return { language: '', country: '' };
        }
        return language;
    },

    getDeviceInfo: function () {
        // dependsOn UAParser
        // https://cdn.jsdelivr.net/npm/ua-parser-js/dist/ua-parser.min.js
        return new UAParser().getResult();
    },

    objectEncode: function (obj) {
        let str = "";
        let first = true;
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (first) {
                    str += key + "=" + encodeURI(obj[key]);
                    first = false;
                } else {
                    str += '&' + key + "=" + encodeURI(obj[key]);
                }
            }
        }
        return str;
    },

    getUnixTimestamp: function () {
        return new Date().getTime();
    },

    // dependsOn crypto-js
    generateJwtToken: function (global, event) {

        function doCreateJwtToken(global, event) {
            const header = JSON.stringify({
                "typ": "JWT",
                "alg": "HS256"
            });
            const seconds = Math.floor(new Date().getTime() / 1000);

            const payload = JSON.stringify({
                "jti": event.jwtId,
                "sub": event.subject,
                "aud": event.audience,
                "iat": seconds,
                "iss": event.issuer,
                "exp": seconds + global.duration
            });

            const headerBase64url = CryptoJS.enc.Base64url.stringify(CryptoJS.enc.Utf8.parse(header));
            const payloadBase64url = CryptoJS.enc.Base64url.stringify(CryptoJS.enc.Utf8.parse(payload));
            let beforeSign = headerBase64url + "." + payloadBase64url;
            let signed = hmacAndBase64Url(beforeSign, global.secretKey);
            return `${beforeSign}.${signed}`;
        }

        function hmacAndBase64Url(data, secretKey) {
            const key = CryptoJS.enc.Utf8.parse(secretKey);
            const hmac = CryptoJS.HmacSHA256(data, key);
            return CryptoJS.enc.Base64url.stringify(hmac);
        }

        return doCreateJwtToken(global, event);
    }
};

/**
 * utils for event submit
 * @returns event util
 */
AnalysisEvent.prototype.eventUtils = {
    // dependsOn crypto-js
    // https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.1.1/crypto-js.min.js
    calculateChecksum: function (event) {
        const eventContent = event.client + "," + event.userId
            + "," + event.uploadTime + "," + event.device
            + "," + event.events;
        return CryptoJS.MD5(eventContent).toString(CryptoJS.enc.Hex);
    },

    buildDeviceInfo: function () {
        const ua = this.utils().getDeviceInfo();
        const locale = this.utils().getLanguageAndRegion();
        return {
            language: locale.language,
            country: locale.country,
            deviceManufacturer: ua.browser.name,
            deviceModel: ua.browser.name,
            versionName: ua.browser.version,
            osName: ua.os.name,
            platform: 'Web',
        };
    },

    buildEventInfo: function (type, currentTimestamp, options) {
        const eventInfo = {};
        eventInfo.eventType = type;
        eventInfo.timestamp = currentTimestamp;
        eventInfo.eventProperties = options.event || Object.entries(options).reduce((acc, [key, value]) => {
            if (key !== 'type') acc[key] = value;
            return acc;
        }, {});
        eventInfo.userProperties = options.user || {};
        return [eventInfo];
    },

    buildEvent: function (globalConfig, eventConfig, type, options) {
        const currentTime = this.utils().getUnixTimestamp();
        const body = {
            client: eventConfig.client,
            userId: globalConfig.userId || '-1',
            uploadTime: currentTime,
        };
        // build deviceInfo
        body.device = JSON.stringify(this.buildDeviceInfo());
        body.events = JSON.stringify(this.buildEventInfo(type, currentTime, options));
        body.checksum = this.calculateChecksum(body);
        return body;
    },

    utils: function () {
        return AnalysisEvent.prototype.utils;
    }
};

/**
 * create Event Handler to submit event
 *
 * @returns EventHandler Object
 */
AnalysisEvent.prototype.globalConfig = {
    domain: "https://event.suunto.com",
    uri: "event/submit",
    retry: 3,
    // token expire,default 3600 seconds
    duration: 3600,
    tokenCache: false,
};

AnalysisEvent.prototype.eventConfig = {
    client: 'apikeyst',
    jwtId: "suunto-event",
    subject: "suunto-line",
    audience: "suuntoapp",
    issuer: "suunto"
};

AnalysisEvent.prototype.logEvent = function (eventType, options) {
    this.submit({
        type: eventType,
        ...options
    });
};

AnalysisEvent.prototype.submit = function (options) {
    try {
        if (!this.loaded) {
            throw new Error("AnalysisEvent is not init");
        }

        if (!options || !options.type) {
            throw new Error("type is missing");
        }

        const globalConfig = this.globalConfig;
        const eventConfig = this.eventConfig;
        const thisEvent = this;
        const type = options.type;

        let jwtToken = this.utils.generateJwtToken(globalConfig, eventConfig);
        // build event object
        const eventObj = this.eventUtils.buildEvent(globalConfig, eventConfig, type, options);
        // convert event object to uri encoding string
        const body = this.utils.objectEncode(eventObj);

        let retry = globalConfig.retry;

        function doSubmit(retry, type, global, event, body, options) {
            let last = false;
            if (retry <= 1) {
                last = true;
            }

            fetch(`${globalConfig.domain}/${globalConfig.uri}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${jwtToken}`
                },
                body: body
            }).then(r => {
                if (thisEvent.utils.requestSuccess(r)) {
                    r.json().then(data => {
                        if (data.code >= 200 && data.code < 400) {
                            thisEvent.utils.safeCall(options.onSuccess, data);
                        } else {
                            if (last) {
                                thisEvent.utils.safeCall(options.onFailure, data);
                                return;
                            }
                            doSubmit(--retry, type, global, event, body, options);
                        }
                    }).catch(err => {
                        //ignore
                    });
                } else {
                    if (last) {
                        thisEvent.utils.safeCall(options.onFailure, r);
                        return;
                    }
                    doSubmit(--retry, type, global, event, body, options);
                }
            }).catch(err => {
                if (last) {
                    thisEvent.utils.safeCall(options.onError, err);
                    return;
                }
                doSubmit(--retry, type, global, event, body, options);
            });
        }

        doSubmit(retry, type, globalConfig, eventConfig, body, options);
    } catch (e) {
        console.log(e);
    }
};

const createAnalysis = (function () {
    let analysis;
    return function () {
        if (analysis) {
            return analysis;
        }
        analysis = new AnalysisEvent();
        return analysis;
    }
})();

const analysisClient = {};
analysisClient.getInstance = function () {
    return createAnalysis();
}

// exports.AnalysisEvent = AnalysisEvent;
export default analysisClient;

