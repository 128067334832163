import { CameraPayload } from './camera/CameraPath';
import Languages from './i18n/Languages';
import { Workout } from './models/Workout';

const makeCachedFetch = <FetchType extends (...args: any[]) => Promise<any>>(fetch: FetchType) => {
  type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

  let cachedPromise: Promise<Awaited<ReturnType<FetchType>>>;

  return (...args: Parameters<FetchType>): Promise<Awaited<ReturnType<FetchType>>> => {
    if (!cachedPromise) {
      cachedPromise = fetch(...args);
    }
    return cachedPromise;
  };
};

export enum Relation {
  public = 'PUBLIC',
}
export interface MetaData {
  relation: Relation;
}

async function api<Payload>(url: string): Promise<Payload> {
  const response = await fetch(url);
  if (!response.ok) {
    throw response;
  }

  return response.json() as Promise<Payload>;
}

export async function fetchWorkout(userName: string, id: string): Promise<Workout> {
  let cameraUrl = process.env.REACT_APP_API_URL || '';
  cameraUrl += `/api/camera/${userName}/${id}${window.location.search}`;
  const cameraPayload = await api<CameraPayload>(cameraUrl);

  if (cameraPayload.error) console.error(cameraPayload.error);

  return new Workout(cameraPayload.workout, cameraPayload, userName, id);
}

const getSuuntoComLanguageByAppLanguage = (lan: string) => {
  const languageObject =
    Object.values(Languages).find(({ language }) => lan === language) || Languages.enUS;
  return languageObject.code;
};

export async function fetchSuuntoFooter(language: string): Promise<string> {
  const apiRoot = process.env.REACT_APP_SUUNTO_WEBSITE_URL;
  const suuntoComLanguage = getSuuntoComLanguageByAppLanguage(language);
  const url = `${apiRoot}/${suuntoComLanguage}/404`;
  const response = await fetch(url);
  return response.text();
}

export interface PopularProduct {
  Categories: number[];
  Name: string;
  ProductVariantName: string;
  MainImagePath: string;
  Description: string;
  Price: number;
  Ssids: string[];
  Url: string;
}

export const enum ProductCategory {
  WATCH = 3,
}

interface CultureLanguage {
  Culture: string /* eg. en-IE */;
  DisplayName: string /* eg. Ireland */;
}

export interface Culture {
  AutoDetectedCulture: string /* eg. en-IE */;
  AutoDetectedCountry: string /* eg. Ireland */;
  Languages: CultureLanguage[];
}

const fetchCulture = async (): Promise<Culture> => {
  const apiRoot = process.env.REACT_APP_SUUNTO_WEBSITE_URL;
  const cultureResponse = await fetch(`${apiRoot}/api/detectculture`);
  return await cultureResponse.json();
};

export const loadCulture = makeCachedFetch(fetchCulture);

interface Cart {
  subtotal: string /* eg. 0.00 */;
  subtotal_formatted: string /* eg. 0.00€ */;
  tax: string /* eg. 0.00 */;
  tax_formatted: string /* eg. 0.00€ */;
  grand_total: string /* eg. 0.00 */;
  grand_total_formatted: string /* eg. 0.00€ */;
  qty: number;
  id: string;
  items: [];
}

interface ProductPrice {
  price: string /* eg. 0.00 */;
}

interface FormattedPrice {
  price: string /* eg. 0.00 € */;
  discount: number /* % */;
  promo: string /* eg. 0.00 € */;
}

interface Product {
  sku: string;
  slm_lib_size: null;
  product_sid: string;
  price: ProductPrice;
  formatted_price: FormattedPrice;
  inventory: {
    qty: number;
    delivery: string /* eg. 1-3 days */;
  };
}

interface Currency {
  code: string /* eg. EUR */;
  symbol: string /* eg. € */;
  name: string /* eg. euro */;
}

interface Customer {
  init_group_customer: string /* eg. ECOM */;
  group_id: number;
}

interface PriceResponse {
  currency: Currency;
  customer: Customer;
  cart: Cart;
  products: Product[];
}

export const fetchPrice = async (productSKU: string): Promise<PriceResponse> => {
  const { AutoDetectedCulture: culture } = await loadCulture();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const query = token ? '?token=' + token : '';

  const priceResult = await fetch(`/price/${culture?.toLowerCase() || ''}/${productSKU}/${query}`);
  return await priceResult.json();
};

export const fetchPopularProducts = async (): Promise<PopularProduct[]> => {
  const apiRoot = process.env.REACT_APP_SUUNTO_WEBSITE_URL;
  const { AutoDetectedCulture } = await loadCulture();
  const popularResult = await fetch(
    `${apiRoot}/api/search/products?activecategory=0&languageId=${AutoDetectedCulture}&page=1&pageid=16098`,
  );
  const popular: PopularProduct[] = (await popularResult.json()).ProductLines;

  return popular
    .filter(({ Categories }) => Categories.includes(ProductCategory.WATCH))
    .map(({ Url, ...other }: PopularProduct) => ({
      ...other,
      Url: `https://www.suunto.com/${Url}`,
    }));
};
