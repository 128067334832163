import { Button, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import namespaces from '../i18n/namespaces';
import { ReactComponent as AlertIcon } from '../images/icons/dive-features/alert_solid_fill.svg';

type ErrorViewProps = {
  classes?: Record<string, string>;
  status: number | boolean;
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.default,
    },
    alertIcon: {
      width: '4rem',
      height: '4rem',
      marginBottom: '-0.5rem',
    },
    title: {
      textTransform: 'uppercase',
      marginTop: '4rem',
    },
    button: {
      marginTop: '4rem',
      padding: '1.2rem 2.5rem',
    },
  }),
  { name: 'ErrorView' },
);

function ErrorView(props: ErrorViewProps): React.ReactElement {
  const { status } = props;
  const classes = useStyles(props);
  const { t } = useTranslation([namespaces.TRANSLATIONS]);
  return (
    <div className={classes.root}>
      <AlertIcon className={classes.alertIcon} />
      {typeof status === 'number' && (
        <Typography variant="body1">
          {t(`${namespaces.TRANSLATIONS}:ERROR_VIEW_STATUS_CODE_ERROR`, { statusCode: status })}
        </Typography>
      )}
      <Typography variant="h1" className={classes.title}>
        <strong>
          {t([
            `${namespaces.TRANSLATIONS}:ERROR_VIEW_${status}_TITLE`,
            `${namespaces.TRANSLATIONS}:ERROR_VIEW_UNSPECIFIC_TITLE`,
          ])}
        </strong>
      </Typography>
      <Typography component="p" variant="h6">
        {t([
          `${namespaces.TRANSLATIONS}:ERROR_VIEW_${status}_SUBTITLE`,
          `${namespaces.TRANSLATIONS}:ERROR_VIEW_UNSPECIFIC_SUBTITLE`,
        ])}
      </Typography>
      <Button
        href="https://suunto.com"
        variant="contained"
        color="primary"
        className={classes.button}
      >
        <strong>{t('GO_TO_HOMEPAGE')}</strong>
      </Button>
    </div>
  );
}

export default ErrorView;
