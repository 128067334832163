import React from 'react';
import { loadCulture } from '../api';
import noOp from '../helpers/noOp';
import {
  getPreferredMeasurementSystem,
  MeasurementSystem,
  MeasurementSystemContext,
  MeasurementSystemContextType,
} from './MeasurementSystem';

type MeasurementServiceProps = {
  classes?: Record<string, string>;
  children: React.ReactElement;
};

function MeasurementSystemService(props: MeasurementServiceProps): React.ReactElement {
  const { children } = props;
  const [measurementSystem, setMeasurementSystem] = React.useState<MeasurementSystem>(
    getPreferredMeasurementSystem(),
  );

  React.useEffect(() => {
    loadCulture().then(getPreferredMeasurementSystem).then(setMeasurementSystem).catch(noOp);
  }, []);

  const measurementSystemValue = React.useMemo<MeasurementSystemContextType>(
    () => [measurementSystem, setMeasurementSystem],
    [measurementSystem],
  );

  return (
    <MeasurementSystemContext.Provider value={measurementSystemValue}>
      {children}
    </MeasurementSystemContext.Provider>
  );
}

export default MeasurementSystemService;
