import React from 'react';

export const useLoading = (initialState = false): [boolean, () => () => void] => {
  const [loading, setLoadingLocal] = React.useState<boolean>(initialState);

  const setLoading = () => {
    setLoadingLocal(true);
    return () => setLoadingLocal(false);
  };

  return [loading, setLoading];
};
