import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import SuuntoLogo from '../images/suunto_logo.svg';
import SuuntoLogoWhite from '../images/suunto_logo_white.svg';

type LoadingViewProps = {
  classes?: Record<string, string>;
};

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      height: '100%',
      width: '100%',
      left: 0,
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.palette.background.default,
      zIndex: 1000,
    },
  }),
  { name: 'LoadingView' },
);

function LoadingView(props: LoadingViewProps): React.ReactElement {
  const classes = useStyles(props);
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <img src={theme.palette.mode === 'dark' ? SuuntoLogoWhite : SuuntoLogo} alt="Suunto logo" />
    </div>
  );
}

export default LoadingView;
