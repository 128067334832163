import { Workout } from '../models/Workout';
import { Vec3, radians } from './Vec3';
import { CatmullRomSegment, CatmullRomSpline } from './CatmullRomSpline';
import { Track, Bounds } from './Track';
import { WorkoutPayload, LocationPoint } from '../types/WorkoutPayload';

/** Longitude, latitude, altitude, optional time. */
export interface LLA {
  lon: number;
  lat: number;
  alt: number;
  t?: number;
}

/** Longitude, latitude, altitude, time. */
export interface LLAT extends LLA {
  t: number;
}

export interface XY {
  x: number;
  y: number;
}

export interface CameraPayload {
  error?: string;
  camera: number[];
  workout: WorkoutPayload;
}

export class InterpolatedChain {
  constructor(public array: number[]) {}

  getValue(t: number): number {
    const position = t * (this.array.length - 1);
    const indexFrom = Math.floor(position);
    const indexTo = Math.ceil(position);
    const valueDelta = this.array[indexTo] - this.array[indexFrom];
    const interpolation = position - indexFrom;

    return this.array[indexFrom] + valueDelta * interpolation;
  }
}

export const TRACK_PROGRESS_FACTOR = 1.1;

export class CameraPath {
  constructor(workout: Workout) {
    const locations = workout.getLocation();
    const points = locations ? locations.locationPoints : [];
    if (points.length) {
      const bounds = Track.getBounds(points);

      this.scale = this.getPseudoCartesianScale({
        lon: bounds.lonCenter,
        lat: bounds.latCenter,
        alt: bounds.altCenter,
      });

      this.track = new Track(this.timesFromDistances(points));
      this.bounds = bounds;
      this.lookAtPoints = this.track.getLookAtPoints(
        this.numberOfLookAtKeys,
        this.lookAtPointCenteringBehaviour,
      );
      this.narrowLookAtPoints = this.track.getLookAtPoints(
        this.numberOfNarrowLookAtKeys,
        this.lookAtPointCenteringBehaviour,
      );
      this.scale = this.getPseudoCartesianScale(this.lookAtPoints[0]);
      this.lookAtCurve = this.getLookAtCurve(this.lookAtPoints);
      this.narrowLookAtCurve = this.getLookAtCurve(this.narrowLookAtPoints);
    } else {
      this.lookAtCurve = new CatmullRomSpline([]);
      this.narrowLookAtCurve = new CatmullRomSpline([]);
    }
    this.cameraPath = new CatmullRomSpline([]);
  }

  static create(workout: Workout, serialized?: CameraPayload): CameraPath {
    const cameraPath = new CameraPath(workout);

    if (serialized) {
      const camera = serialized.camera;
      const spline = cameraPath.cameraPath;

      for (let i = 0; i < camera.length; i += 12) {
        const a = new Vec3(camera[i + 0], camera[i + 1], camera[i + 2]);
        const b = new Vec3(camera[i + 3], camera[i + 4], camera[i + 5]);
        const c = new Vec3(camera[i + 6], camera[i + 7], camera[i + 8]);
        const d = new Vec3(camera[i + 9], camera[i + 10], camera[i + 11]);
        const segment = new CatmullRomSegment(a, b, c, d);
        segment.a = a;
        segment.b = b;
        segment.c = c;
        segment.d = d;
        spline.segments.push(segment);
      }
    }

    return cameraPath;
  }

  timesFromDistances(points: LocationPoint[]): LocationPoint[] {
    let distance = 0;
    let xyPrev: Vec3 | undefined;

    return points.map((origPoint) => {
      const point = { ...origPoint };

      const xy = this.getPseudoCartesianCoordinatesFromLatLonAlt({
        lon: point.longitude,
        lat: point.latitude,
        alt: point.altitude,
      });

      if (xyPrev) {
        const dx = xy.x - xyPrev.x;
        const dy = xy.y - xyPrev.y;
        distance += Math.sqrt(dx * dx + dy * dy);
      }

      point.timestamp = distance;

      xyPrev = xy;
      return point;
    });
  }

  getPseudoCartesianScale(point: LLA): XY {
    const circumference = 40075017; // Earth circumference in meters (at Equator)
    return {
      x: (Math.cos(radians(point.lat)) * circumference) / 360,
      y: circumference / 360,
    };
  }

  getPseudoCartesianCoordinatesFromLatLonAlt(point: LLA): Vec3 {
    const x = point.lon * this.scale.x;
    const y = point.lat * this.scale.y;
    const z = point.alt;

    return new Vec3(x, y, z);
  }

  getLatLonAltFromPseudoCartesianCoordinates(vec: Vec3): LLA {
    return {
      lat: vec.y / this.scale.y,
      lon: vec.x / this.scale.x,
      alt: vec.z,
    };
  }

  getLookAtCurve(lookAtPoints: LLAT[]): CatmullRomSpline {
    const iMax = lookAtPoints.length - 1;

    const firstPoint = {
      lat: 2 * lookAtPoints[0].lat - lookAtPoints[1].lat,
      lon: 2 * lookAtPoints[0].lon - lookAtPoints[1].lon,
      alt: 2 * lookAtPoints[0].alt - lookAtPoints[1].alt,
      t: 2 * lookAtPoints[0].t - lookAtPoints[1].t,
    };
    const lastPoint = {
      lat: 2 * lookAtPoints[iMax].lat - lookAtPoints[iMax - 1].lat,
      lon: 2 * lookAtPoints[iMax].lon - lookAtPoints[iMax - 1].lon,
      alt: 2 * lookAtPoints[iMax].alt - lookAtPoints[iMax - 1].alt,
      t: 2 * lookAtPoints[iMax].t - lookAtPoints[iMax - 1].t,
    };

    const points: Vec3[] = [];

    points.push(this.getPseudoCartesianCoordinatesFromLatLonAlt(firstPoint));
    lookAtPoints.forEach((point) => {
      points.push(this.getPseudoCartesianCoordinatesFromLatLonAlt(point));
    });
    points.push(this.getPseudoCartesianCoordinatesFromLatLonAlt(lastPoint));

    return new CatmullRomSpline(points);
  }

  getCameraPosition(
    referencePoint: Vec3,
    distance: number,
    azimuth: number,
    elevation: number,
  ): Vec3 {
    const azimuthRad = radians(azimuth);
    const elevationRad = radians(elevation);
    const xy = Math.cos(elevationRad);
    const dx = distance * xy * Math.sin(azimuthRad);
    const dy = distance * xy * Math.cos(azimuthRad);
    const dz = distance * Math.sin(elevationRad);

    return new Vec3(referencePoint.x + dx, referencePoint.y + dy, referencePoint.z + dz);
  }

  numberOfLookAtKeys = 16;
  numberOfNarrowLookAtKeys = 64;
  numberOfCameraKeys = 12;
  /** Camera behaviour at different keyframes.
   * 0 centers on marker along track, 1 centers on track bounding box center. */
  lookAtPointCenteringBehaviour = new InterpolatedChain([0, 0, 0, 0, 0, 0, 0, 0.1, 0.2, 0.5, 1.0]);

  playDuration = 40000;
  currentProgress = 0;
  cameraEasing = (t: number): number => {
    t *= 0.92;
    return 1.2 * t * (1 - Math.pow(t, 10)) + Math.pow(t, 12);
  };

  track?: Track;
  bounds?: Bounds;
  lookAtPoints?: LLAT[];
  narrowLookAtPoints?: LLAT[];
  scale: XY = { x: 1, y: 1 };
  lookAtCurve: CatmullRomSpline;
  narrowLookAtCurve: CatmullRomSpline;
  cameraPath: CatmullRomSpline;
}
