export class Vec3 {
  constructor(public x: number, public y: number, public z: number) {}

  multiply(value: number): Vec3 {
    return new Vec3(this.x * value, this.y * value, this.z * value);
  }

  divide(value: number): Vec3 {
    return new Vec3(this.x / value, this.y / value, this.z / value);
  }

  add(vec: Vec3): Vec3 {
    return new Vec3(this.x + vec.x, this.y + vec.y, this.z + vec.z);
  }

  subtract(vec: Vec3): Vec3 {
    return new Vec3(this.x - vec.x, this.y - vec.y, this.z - vec.z);
  }

  length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
  }

  normalize(): Vec3 {
    const length = this.length();
    return length == 0 ? new Vec3(0, 0, 0) : this.multiply(1 / length);
  }

  distance(vec: Vec3): number {
    const dx = vec.x - this.x;
    const dy = vec.y - this.y;
    const dz = vec.z - this.z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
  }
}

export function radians(deg: number): number {
  return deg * (Math.PI / 180);
}

export function degrees(rad: number): number {
  return rad / (Math.PI / 180);
}
