import { Workout } from '../models/Workout';
import {
  AltitudeStreamExtension,
  HeartrateStreamExtension,
  SpeedStreamExtension,
  ValuePointExtension,
} from '../types/WorkoutPayload';

interface PathConfigurationItem {
  getExtension: (workout: Workout) => ValuePointExtension | undefined;
}

const getSpeedExtension = (workout: Workout): SpeedStreamExtension | undefined =>
  workout.getSpeed();

const PathConfiguration: Record<string, PathConfigurationItem | undefined> = {
  HeartRate: {
    getExtension: (workout: Workout): HeartrateStreamExtension | undefined =>
      workout.getHeartRate(),
  },
  Pace: {
    getExtension: getSpeedExtension,
  },
  Speed: {
    getExtension: getSpeedExtension,
  },
  Altitude: {
    getExtension: (workout: Workout): AltitudeStreamExtension | undefined => workout.getAltitude(),
  },
  Cadence: undefined,
  Epoc: undefined,
  Temperature: undefined,
  SeaLevelPressure: undefined,
  BikeCadence: undefined,
  StrokeRate: undefined,
  SwimPace: undefined,
  Swolf: undefined,
  SpeedKnots: undefined,
  Depth: undefined,
  VerticalSpeed: undefined,
};
export default PathConfiguration;
