import React, { ReactElement } from 'react';

export const DeveloperContext = React.createContext<boolean>(false);
DeveloperContext.displayName = 'DeveloperContext';

interface DebugServiceProps {
  children: ReactElement;
}

const setIsDeveloperToLocalStorage = (newIsDeveloper: boolean) => {
  window.localStorage.setItem('isDeveloper', newIsDeveloper.toString());
  window.dispatchEvent(new Event('storage'));
};

const getIsDeveloper = () => window.localStorage.getItem('isDeveloper') === 'true';

export const DeveloperService = ({ children }: DebugServiceProps): ReactElement => {
  const [isDeveloper, setIsDeveloper] = React.useState<boolean>(getIsDeveloper());

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.isDeveloper = setIsDeveloperToLocalStorage;
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete window.isDeveloper;
    };
  }, []);

  React.useEffect(() => {
    const storageListener = () => {
      setIsDeveloper(getIsDeveloper());
    };
    window.addEventListener('storage', storageListener);
    return () => {
      window.removeEventListener('storage', storageListener);
    };
  }, []);
  return <DeveloperContext.Provider value={isDeveloper}>{children}</DeveloperContext.Provider>;
};
