import { useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, Theme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import React, { ReactElement } from 'react';
import amplitude from 'amplitude-js';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './i18n/i18n';
import { AmplitudeContext } from './Amplitude/Amplitude';
import { DeveloperService } from './Developer/Developer';
import MeasurementSystemService from './MeasurementSystem/MeasurementSystemService';
import Routes from './Routes';
import { dark, light } from './theme/theme';
import WorkoutRoute from './Workout/WorkoutRoute';

amplitude.getInstance().init({
  domain: process.env.REACT_APP_ANALYSIS_DOMAIN,
  secretKey: process.env.REACT_APP_ANALYSIS_SECRETKEY,
  userId: (function getUserId() {
    try {
      return window.location.href.replace(/https?:\/\/[^/]+\/(?:workout|move)\/([^/]+)\/.*/g, '$1');
    } catch (e) {
      return 'undefined';
    }
  })(),
  client: process.env.REACT_APP_ANALYSIS_CLIENTID,
});

function App(): ReactElement {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo<Theme>(
    () => createTheme(prefersDarkMode ? dark : light),
    [prefersDarkMode],
  );

  return (
    <MeasurementSystemService>
      <AmplitudeContext.Provider value={{}}>
        <DeveloperService>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Router>
                <Switch>
                  <Route path={Routes.workout} component={WorkoutRoute} />
                </Switch>
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </DeveloperService>
      </AmplitudeContext.Provider>
    </MeasurementSystemService>
  );
}

export default App;
