import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { fetchWorkout } from '../api';
import ErrorView from '../Error/ErrorView';
import { useLoading } from '../helpers/useLoading';
import LoadingView from '../Loading/LoadingView';
import { Workout as WorkoutModel } from '../models/Workout';
import { PositionService } from './usePosition';

const Workout = React.lazy(() => import(/* webpackChunkName: 'Workout' */ './Workout'));

interface WorkoutRouteParams {
  workoutId: string;
  userName: string;
}

function WorkoutRoute(): React.ReactElement {
  const { workoutId, userName } = useParams<WorkoutRouteParams>();
  const [workout, setWorkout] = React.useState<WorkoutModel | undefined>(undefined);
  const [error, setError] = React.useState<number | boolean | null>(null);
  const [workoutLoading, setWorkoutLoading] = useLoading(true);

  React.useEffect(() => {
    fetchWorkout(userName, workoutId)
      .then(setWorkout)
      .catch((response) => setError(response.status || true))
      .finally(setWorkoutLoading());
  }, [workoutId, userName]);

  if (error) {
    return <ErrorView status={error} />;
  }

  return (
    <>
      <Suspense fallback={<LoadingView />}>
        <PositionService key={workoutId}>
          <Workout key={workoutId} workout={workout} workoutLoading={workoutLoading} />
        </PositionService>
      </Suspense>
      {workoutLoading && <LoadingView />}
    </>
  );
}

export default WorkoutRoute;
