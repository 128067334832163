import {
  AltitudeStreamExtension,
  DiveHeaderExtension,
  Extension,
  Extensions,
  FitnessExtension,
  HeartrateStreamExtension,
  LocationStreamExtension,
  MultisportMarkerExtension,
  SkiExtension,
  SpeedStreamExtension,
  SummaryExtension,
  SwimmingHeaderExtension,
  ValuePoint,
  WorkoutExtensionType,
  WorkoutPayload,
} from '../types/WorkoutPayload';
import PathConfiguration from '../Workout/PathConfiguration';
import { MercatorCoordinate } from '!mapbox-gl';
import { CameraPath, CameraPayload } from '../camera/CameraPath';

export interface POI {
  label: string;
  value: number;
  lon: number;
  lat: number;
  position: number;
}

export interface Route {
  poi: POI[];
  pts: [number, number, number | null, number, number?][];
}

type LngLat = [number, number];

function isType(extensionType: WorkoutExtensionType | 'MultisportMarker') {
  return <T extends Extension>(ext: Extension): ext is T => ext.type === extensionType;
}

const HIDE_MIN: Record<string, boolean> = {
  Pace: true,
  Speed: true,
};

export class Workout {
  static getExtension<ExtensionType extends Extension>(
    extensions: Extensions,
    extensionType: WorkoutExtensionType | 'MultisportMarker',
  ): ExtensionType | undefined {
    return extensions.find<ExtensionType>(isType(extensionType));
  }

  static getRoute(graph: string, workout: Workout, labels: { min: string; max: string }): Route {
    const locations = workout.getLocation();
    const valuePointExtension = PathConfiguration[graph]?.getExtension(workout);
    const min: POI = { label: labels.min, value: Infinity, lon: 0, lat: 0, position: 0 };
    const max: POI = { label: labels.max, value: -Infinity, lon: 0, lat: 0, position: 0 };
    const poi: POI[] = [max];
    if (!HIDE_MIN[graph]) {
      poi.push(min);
    }
    const pts: [number, number, number | null, number, number?][] = [];

    if (!locations) return { poi, pts };

    const points = locations.locationPoints;
    let values: ValuePoint[] = [];

    if (valuePointExtension) {
      if (valuePointExtension.points) {
        values = valuePointExtension.points;
      } else if (valuePointExtension.timestamps && valuePointExtension.values) {
        for (let num = 0; num < valuePointExtension.timestamps.length; ++num) {
          values.push({
            timestamp: valuePointExtension.timestamps[num],
            value: valuePointExtension.values[num],
          });
        }
      }
    }

    const pointCount = points.length;
    const valueCount = values.length;
    const interpolateValues = false;
    let pointNum = 0;
    let valueNum = 0;
    let stamp = points[0].timestamp;
    const last = points[points.length - 1].timestamp;
    let value: ValuePoint | null;

    if (!valueCount || values[0].timestamp > stamp) {
      value = null;
    } else {
      do {
        value = values[valueNum++];
      } while (valueNum < valueCount && value.timestamp < stamp);
    }

    let point = points[pointNum];
    let pointPrev = point;
    let valuePrev = value;
    value = values[valueNum];

    while (stamp < last) {
      if (valueNum >= valueCount || point.timestamp < value.timestamp) {
        stamp = point.timestamp;
        let valueCurrent: number | null = null;

        if (valuePrev !== null && valueNum < valueCount) {
          const scale =
            (stamp - valuePrev.timestamp) / (value.timestamp - valuePrev.timestamp || 1);
          valueCurrent = valuePrev.value + (value.value - valuePrev.value) * scale;
        }

        pts.push([point.latitude, point.longitude, valueCurrent, stamp]);

        pointPrev = point;
        while (pointNum < pointCount && point.timestamp == pointPrev.timestamp) {
          point = points[++pointNum];
        }
      } else if (point.timestamp > value.timestamp) {
        if (interpolateValues) {
          stamp = value.timestamp;
          const scale =
            (stamp - pointPrev.timestamp) / (point.timestamp - pointPrev.timestamp || 1);

          pts.push([
            pointPrev.latitude + (point.latitude - pointPrev.latitude) * scale,
            pointPrev.longitude + (point.longitude - pointPrev.longitude) * scale,
            value.value,
            stamp,
          ]);
        }

        valuePrev = value;
        while (valueNum < valueCount && value.timestamp == valuePrev.timestamp) {
          value = values[++valueNum];
        }
      } else {
        stamp = point.timestamp;

        pts.push([point.latitude, point.longitude, value.value, stamp]);

        pointPrev = point;
        valuePrev = value;
        while (pointNum < pointCount && point.timestamp == pointPrev.timestamp) {
          point = points[++pointNum];
        }
        while (valueNum < valueCount && value.timestamp == valuePrev.timestamp) {
          value = values[++valueNum];
        }
      }
    }

    let ptMin: [number, number, number | null, number, number?] | undefined;
    let ptMax: [number, number, number | null, number, number?] | undefined;

    for (const pt of pts) {
      if (pt[2] !== null) {
        if (pt[2] < min.value) {
          ptMin = pt;
          min.value = pt[2];
        }
        if (pt[2] > max.value) {
          ptMax = pt;
          max.value = pt[2];
        }
      }
    }

    for (const pt of pts) {
      pt[2] =
        pt[2] === null ? 1 : Math.floor(((pt[2] - min.value) * 254) / (max.value - min.value));
    }

    let pt = pts[0];
    let xyzPrev = MercatorCoordinate.fromLngLat({ lng: pt[1], lat: pt[0] });
    let d = 0;

    for (pt of pts) {
      const xyz = MercatorCoordinate.fromLngLat({ lng: pt[1], lat: pt[0] });

      const dx = xyz.x - xyzPrev.x;
      const dy = xyz.y - xyzPrev.y;
      d += Math.sqrt(dx * dx + dy * dy);
      pt[4] = d;

      xyzPrev = xyz;
    }

    if (ptMin) {
      min.lon = ptMin[1];
      min.lat = ptMin[0];
      min.position = ptMin[4] as number;
    }

    if (ptMax) {
      max.lon = ptMax[1];
      max.lat = ptMax[0];
      max.position = ptMax[4] as number;
    }

    return { poi, pts };
  }

  showMap(): boolean {
    const { availableExtensions, centerPosition: { x, y } = {} } = this.workout;
    return Boolean(
      availableExtensions.includes(WorkoutExtensionType.LocationStreamExtension) || (x && y),
    );
  }
  getSummary(): SummaryExtension | undefined {
    return Workout.getExtension<SummaryExtension>(
      this.workout.extensions,
      WorkoutExtensionType.SummaryExtension,
    );
  }
  getFitness(): FitnessExtension | undefined {
    return Workout.getExtension<FitnessExtension>(
      this.workout.extensions,
      WorkoutExtensionType.FitnessExtension,
    );
  }
  getSpeed(): SpeedStreamExtension | undefined {
    return Workout.getExtension<SpeedStreamExtension>(
      this.workout.extensions,
      WorkoutExtensionType.SpeedStreamExtension,
    );
  }
  getAltitude(): AltitudeStreamExtension | undefined {
    return Workout.getExtension<AltitudeStreamExtension>(
      this.workout.extensions,
      WorkoutExtensionType.AltitudeStreamExtension,
    );
  }
  getLocation(): LocationStreamExtension | undefined {
    return Workout.getExtension<LocationStreamExtension>(
      this.workout.extensions,
      WorkoutExtensionType.LocationStreamExtension,
    );
  }
  getHeartRate(): HeartrateStreamExtension | undefined {
    return Workout.getExtension<HeartrateStreamExtension>(
      this.workout.extensions,
      WorkoutExtensionType.HeartrateStreamExtension,
    );
  }
  getDiveHeaderExtension(): DiveHeaderExtension | undefined {
    return Workout.getExtension<DiveHeaderExtension>(
      this.workout.extensions,
      WorkoutExtensionType.DiveHeaderExtension,
    );
  }
  getSwimmingHeaderExtension(): SwimmingHeaderExtension | undefined {
    return Workout.getExtension<SwimmingHeaderExtension>(
      this.workout.extensions,
      WorkoutExtensionType.SwimmingHeaderExtension,
    );
  }
  getSkiExtension(): SkiExtension | undefined {
    return Workout.getExtension<SkiExtension>(
      this.workout.extensions,
      WorkoutExtensionType.SkiExtension,
    );
  }
  getMultisportMarkerExtension(): MultisportMarkerExtension | undefined {
    return Workout.getExtension<MultisportMarkerExtension>(
      this.workout.extensions,
      'MultisportMarker',
    );
  }
  workout: WorkoutPayload;
  center: LngLat;

  constructor(
    workout: WorkoutPayload,
    cameraPayload: CameraPayload,
    public user: string,
    public id: string,
  ) {
    this.workout = workout;
    this.center = [workout.centerPosition.x, workout.centerPosition.y];
    this.cameraPath = CameraPath.create(this, cameraPayload);
  }

  cameraPath: CameraPath;
}
