export default {
  fiFI: {
    language: 'fi',
    code: 'fi-FI',
    label: 'Suomi',
  },
  enUS: {
    language: 'en',
    code: 'en-US',
    label: 'English',
  },
  frFR: {
    language: 'fr',
    code: 'fr-FR',
    label: 'Française',
  },
  itIT: {
    language: 'it',
    code: 'it-IT',
    label: 'Italiano',
  },
  ruRU: {
    language: 'ru',
    code: 'ru-RU',
    label: 'Pусский',
  },
  koKR: {
    language: 'ko',
    code: 'ko-KR',
    label: 'Korea',
  },
  svSE: {
    language: 'sv',
    code: 'sv-SE',
    label: 'Svenska',
  },
  csCZ: {
    language: 'cs',
    code: 'cs-CZ',
    label: 'Čeština',
  },
  slSI: {
    language: 'sl',
    code: 'sl-SI',
    label: 'Slovenščina',
  },
  plPL: {
    language: 'pl',
    code: 'pl-PL',
    label: 'Polski',
  },
  elGR: {
    language: 'el',
    code: 'el-GR',
    label: 'Ελληνικά',
  },
  skSK: {
    language: 'sk',
    code: 'sk-SK',
    label: 'Slovenčina',
  },
  nbNO: {
    language: 'nb',
    code: 'nb-NO',
    label: 'Norsk bokmål',
  },
  deDE: {
    language: 'de',
    code: 'de-DE',
    label: 'Deutsch',
  },
  huHU: {
    language: 'hu',
    code: 'hu-HU',
    label: 'Magyar',
  },
  nlNL: {
    language: 'nl',
    code: 'nl-NL',
    label: 'Nederlands',
  },
  ptPT: {
    language: 'pt',
    code: 'pt-PT',
    label: 'Português',
  },
  daDK: {
    language: 'da',
    code: 'da-DK',
    label: 'Dansk',
  },
  thTH: {
    language: 'th',
    code: 'th-TH',
    label: 'ไทย',
  },
  zhCHS: {
    language: 'zh',
    code: 'zh-CHS',
    label: '中文',
  },
  idID: {
    language: 'id',
    code: 'id-ID',
    label: 'Bahasa Indonesia',
  },
  jaJP: {
    language: 'ja',
    code: 'ja-JP',
    label: '日本語',
  },
  esES: {
    language: 'es',
    code: 'es-ES',
    label: 'Español',
  },
  trTR: {
    language: 'tr',
    code: 'tr-TR',
    label: 'Türkçe',
  },
  viVI: {
    language: 'vi',
    code: 'vi-VN',
    label: 'Việt Nam',
  },
};
