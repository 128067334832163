import { Vec3 } from './Vec3';

export class CatmullRomSpline {
  constructor(points: Vec3[]) {
    for (let i = 0; i < points.length - 3; i++) {
      this.segments.push(
        new CatmullRomSegment(points[i], points[i + 1], points[i + 2], points[i + 3]),
      );
    }
  }

  getPoint(t: number): Vec3 {
    const tt = t * this.segments.length;
    const i = Math.min(Math.floor(tt), this.segments.length - 1);

    return this.segments[i].getPoint(tt - i);
  }

  segments: CatmullRomSegment[] = [];
}

export class CatmullRomSegment {
  constructor(p0: Vec3, p1: Vec3, p2: Vec3, p3: Vec3) {
    const alpha = 0.5;
    const tension = 0;

    const t0 = 0;
    const t1 = Math.pow(p0.distance(p1), alpha) + t0;
    const t2 = Math.pow(p1.distance(p2), alpha) + t1;
    const t3 = Math.pow(p2.distance(p3), alpha) + t2;

    const m11 = p1.subtract(p0).divide(t1 - t0);
    const m12 = p2.subtract(p0).divide(t2 - t0);
    const m13 = p2.subtract(p1).divide(t2 - t1);
    const m1 = m11
      .subtract(m12)
      .add(m13)
      .multiply(t2 - t1)
      .multiply(1 - tension);

    const m21 = p2.subtract(p1).divide(t2 - t1);
    const m22 = p3.subtract(p1).divide(t3 - t1);
    const m23 = p3.subtract(p2).divide(t3 - t2);
    const m2 = m21
      .subtract(m22)
      .add(m23)
      .multiply(t2 - t1)
      .multiply(1 - tension);

    this.a = p1.subtract(p2).multiply(2).add(m1).add(m2);
    this.b = p1.subtract(p2).multiply(-3).subtract(m1).subtract(m1).subtract(m2);
    this.c = m1;
    this.d = p1;
  }

  getPoint(t: number): Vec3 {
    return this.a
      .multiply(t * t * t)
      .add(this.b.multiply(t * t))
      .add(this.c.multiply(t))
      .add(this.d);
  }

  a: Vec3;
  b: Vec3;
  c: Vec3;
  d: Vec3;
}
